import React, { useState } from 'react';
import { Card, Avatar, Text, Modal } from '@mantine/core';
import { PersonDetailsModal } from './PersonDetailsModal';
import {Person} from '../../types/Person'

interface FamilyMemberProps {
    member: Person;
    onUpdate: (updatedMember: Person) => void;
}

export const PersonCard: React.FC<FamilyMemberProps> = ({ member, onUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCardClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleUpdateMember = (updatedMember: Person) => {
        onUpdate(updatedMember);
    };

    return (
        <>
            <Card
                key={member.id}
                shadow="xs"
                radius="md"
                style={{
                    width: 'calc((100% - 30px) / 4)',
                    aspectRatio: '1.0',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer', // Add this to indicate it's clickable
                }}
                onClick={handleCardClick} // Add this to handle the click event
            >
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Avatar
                        src={member.profilePictureUrl}
                        radius="xl"
                        alt={member.name}
                        style={{
                            width: '120px',
                            height: '120px',
                            marginBottom: '20px',
                        }}
                    />
                    <div style={{textAlign: 'center'}}>
                        <Text size="lg">{member.name}</Text>
                        <Text size="sm" color="gray">
                            {member.dateOfBirth}
                        </Text>
                    </div>
                </div>
            </Card>

            <Modal
                opened={isModalOpen}
                onClose={handleCloseModal}
                size="xl"
                title={`${member.name} - Family Information`}
            >
                <PersonDetailsModal
                    member={member}
                    onUpdate={handleUpdateMember}
                    onClose={handleCloseModal}
                />
            </Modal>
        </>
    );
}