import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Text, Select, TextInput, Group, Stack, ActionIcon } from '@mantine/core';
import { IconTrash, IconPlus } from '@tabler/icons-react';
import {Person, Relationship} from "../../types/Person"


interface RelationshipsPanelProps {
    member: Person;
    onUpdate: (updatedMember: Person) => void;
}

export const RelationshipsPanel: React.FC<RelationshipsPanelProps> = ({ member, onUpdate }) => {
    const [relationships, setRelationships] = useState<Relationship[]>(member.relationships || []);
    const prevValidRelationshipsRef = useRef<Relationship[]>([]);

    const relationshipTypes = ['Mother', 'Father', 'Sibling', 'Spouse', 'Child'];
    const familyMemberNames = ["Joel Raper", "Shannon Raper", "Grace Raper", "Ava Raper", "Grant Webster", "Tiffany Webster"];

    const handleAddRelationship = () => {
        setRelationships([...relationships, { type: null, name: null }]);
    };

    const handleRemoveRelationship = (index: number) => {
        const updatedRelationships = relationships.filter((_, i) => i !== index);
        setRelationships(updatedRelationships);
    };

    const handleUpdateRelationship = (index: number, field: 'type' | 'name', value: string | null) => {
        const updatedRelationships = relationships.map((rel, i) =>
            i === index ? { ...rel, [field]: value } : rel
        );
        setRelationships(updatedRelationships);
    };

    const areRelationshipsEqual = (rel1: Relationship[], rel2: Relationship[]) => {
        if (rel1.length !== rel2.length) return false;
        return rel1.every((rel, index) =>
            rel.type === rel2[index].type && rel.name === rel2[index].name
        );
    };

    useEffect(() => {
        const validRelationships = relationships.filter(rel => rel.type && rel.name);

        if (!areRelationshipsEqual(validRelationships, prevValidRelationshipsRef.current)) {
            console.log('Valid relationships changed:', validRelationships);
            onUpdate({ ...member, relationships: validRelationships });
            prevValidRelationshipsRef.current = validRelationships;
        }
    }, [relationships, member, onUpdate]);

    return (
        <Box mt={20} p={20}>
            <Stack gap="md">
                {relationships.length === 0 ? (
                    <Text>No relationships have been added yet.</Text>
                ) : (
                    relationships.map((relationship, index) => (
                        <Group key={index} justify={"space-between"} align="center">
                            <Select
                                data={relationshipTypes}
                                value={relationship.type}
                                onChange={(value) => handleUpdateRelationship(index, 'type', value)}
                                placeholder="Select relationship type"
                                style={{ flex: 1 }}
                            />
                            <Select
                                data={familyMemberNames}
                                value={relationship.name}
                                onChange={(value) => handleUpdateRelationship(index, 'name', value)}
                                placeholder="Select Person"
                                style={{ flex: 1 }}
                            />
                            <ActionIcon color="red" onClick={() => handleRemoveRelationship(index)} variant={"outline"}>
                                <IconTrash size={16} />
                            </ActionIcon>
                        </Group>
                    ))
                )}

                <Button
                    onClick={handleAddRelationship}
                    leftSection={<IconPlus size={16} />}
                    variant="outline"
                >
                    Add Relationship
                </Button>
            </Stack>
        </Box>
    );
};