import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Blockquote,
    Button,
    Divider,
    Flex,
    Title,
    Text,
    Paper,
    Loader,
    Checkbox,
    Group,
    Modal,
    TextInput,
    Center,
    Textarea,
    Autocomplete,
    Tooltip,
    TagsInput,
    Card,
    Box,
    Image,
    Fieldset,
    Grid,
    Overlay,
    Avatar,
    FocusTrap,
    Skeleton
} from '@mantine/core';
import {
    IconInfoCircle,
    IconCheck,
    IconTrash,
    IconX,
    IconArrowRight,
    IconSparkles,
    IconPencil
} from '@tabler/icons-react';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { MonthPickerInput } from '@mantine/dates';
import ImageWithSkeleton from './ImageWithSkeleton';
import { ImageData } from '../types/ImageData';

interface GroupImagesManuallyProps {
    containerWidth: number | null;
    userId: string;
    auth: AuthenticationResultType;
}

interface FaceToId {
    'person_key': string;
    'person_name': string;
    'face_key': string;
    'face_url': string;
    'face_rekognition_id': string;
}

interface People {
    'person_name': string;
    'person_key': string;
}

interface FaceDataFromImages {
    'people': People[];
    'confirmed_face': FaceToId[];
    'unconfirmed_face': FaceToId[];
}


export function GroupImagesManually({ containerWidth, userId, auth }: GroupImagesManuallyProps) {
    const [images, setImages] = useState<ImageData[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(20);
    const [hasMore, setHasMore] = useState(true);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [eventDate, setEventDate] = useState<Date | null>(null);
    const [eventDescription, setEventDescription] = useState('');
    const [location, setLocation] = useState('');
    const [modalPage, setModalPage] = useState(1);
    const [imageLoadingStatus, setImageLoadingStatus] = useState<{ [key: string]: boolean }>({});
    const [faceSuggestions, setFaceSuggestions] = useState<FaceToId[]>([]);
    const [confirmedFaces, setConfirmedFaces] = useState<{ [key: string]: boolean }>({});
    const [tags, setTags] = useState<string[]>([]);
    const [isEventDescriptionAI, setIsEventDescriptionAI] = useState(true);
    const [isLocationAI, setIsLocationAI] = useState(true);
    const [editingName, setEditingName] = useState<string>("");
    const [hoveredCard, setHoveredCard] = useState<string>("");
    const [editingValue, setEditingValue] = useState<string>("");
    const [knownPeople, setKnownPeople] = useState<string[]>([]);
    const [personMap, setPersonMap] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        fetchUngroupedImages(page);
    }, [page]);


    const fetchUngroupedImages = async (page: number) => {
        setLoading(true);
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/gallery";
            const id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: {
                    hideUnknown: true.toString(), // this is actually being used to only return ungrouped until api is updated!
                    sub: userId.toString(),
                    page: page.toString(),
                    limit: limit.toString(),
                },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            let newImages: ImageData[] = [];

            if (typeof response.data.body === 'string') {
                try {
                    const parsedBody = JSON.parse(response.data.body);
                    if (Array.isArray(parsedBody)) {
                        newImages = parsedBody;
                    }
                } catch (parseError) {
                    // Silently handle parse error
                }
            } else if (Array.isArray(response.data.body)) {
                newImages = response.data.body;
            }

            // Filter out images that already belong to an event
            const ungroupedImages = newImages.filter(image => !image.event_key);

            if (Array.isArray(ungroupedImages) && ungroupedImages.length > 0) {
                setImages((prevImages) => [...prevImages, ...ungroupedImages]);
                setHasMore(images.length === limit);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    const loadMoreImages = () => {
        if (hasMore && !loading) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const handleImageSelect = (imageKey: string) => {
        setSelectedImages(prev =>
            prev.includes(imageKey)
                ? prev.filter(key => key !== imageKey)
                : [...prev, imageKey]
        );
    };

    const handleCreateEvent = async () => {
        if (selectedImages.length === 0) {
            alert('Please select at least one image to create an event.');
            return;
        }
        setLoading(true);
        try {
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/faces/in-images";
            const id_token = auth.IdToken;

            const response = await axios.post(apiUrl, {
                sub: userId,
                image_keys: selectedImages
            }, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            // Parse the JSON string in response.data
            console.log(response.data)
            const parsedData: FaceDataFromImages = JSON.parse(response.data.body);
            console.log(parsedData);

            console.log(parsedData.unconfirmed_face);
            setFaceSuggestions(parsedData.unconfirmed_face);

            console.log(parsedData.people);
            setKnownPeople(parsedData.people.map(person => person.person_name));

            const personMap: { [key: string]: string } = {};
            parsedData.people.forEach(person => {
                personMap[person.person_name] = person.person_key;
            });
            setPersonMap(personMap);

            setConfirmedFaces(
                parsedData.unconfirmed_face.reduce((acc: { [key: string]: boolean }, suggestion: FaceToId) => {
                    acc[suggestion.face_key] = true;
                    return acc;
                }, {})
            );
            setModalOpen(true);
            setModalPage(1);
        } catch (error) {
            console.error('Error fetching face suggestions:', error);
            alert('An error occurred while fetching face suggestions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleModalNext = () => {
        setModalPage(prevPage => prevPage + 1);
    };


    const confirmCreateEvent = async () => {
        try {
            const newEventUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/event/new";
            const id_token = auth.IdToken;

            const response = await axios.post(
                newEventUrl,
                {
                    sub: userId,
                    include: selectedImages,
                    exclude: [],
                    eventDate: eventDate?.toISOString(),
                    eventDescription,
                    location,
                    tags
                },
                {
                    headers: {
                        'Authorization': `Bearer ${id_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                alert('Event created successfully!');
                setImages(prevImages => prevImages.filter(image => !selectedImages.includes(image.key)));
                setSelectedImages([]);
                setModalOpen(false);
                setEventDate(null);
                setEventDescription('');
                setLocation('');
                setTags([]);
                setModalPage(1);
            } else {
                console.error(`Backend Error: Status code ${response.status}, Response data: ${JSON.stringify(response.data)}`);
                alert('Failed to create event. Please try again.');
            }
        } catch (error) {
            console.error('Error creating event:', error);
            alert('An error occurred while creating the event. Please try again.');
        }
    };

    const handleUntrackFace = async (faceKey: string) => {
        try {
            // API call to untrack face
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/face/untrack";
            const id_token = auth.IdToken;

            await axios.post(apiUrl, {
                sub: userId,
                face_key: faceKey
            }, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            // Remove face from local state
            setFaceSuggestions(prevSuggestions =>
                prevSuggestions.filter(suggestion => suggestion.face_key !== faceKey)
            );
            setConfirmedFaces(prev => {
                const { [faceKey]: _, ...rest } = prev;
                return rest;
            });
        } catch (error) {
            console.error('Error untracking face:', error);
            alert('An error occurred while untracking the face. Please try again.');
        }
    };

    const handleImageLoad = (key: string) => {
        setImageLoadingStatus(prevStatus => ({ ...prevStatus, [key]: false }));
    };


    const handleNameEdit = async (faceKey: string, newName: string) => {
        const currentSuggestion = faceSuggestions.find(suggestion => suggestion.face_key === faceKey);
        if (!currentSuggestion || currentSuggestion.person_name === newName) {
            setEditingName("");
            return;
        }

        try {
            // API call to update the name
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/face/update-name";
            const id_token = auth.IdToken;

            await axios.post(apiUrl, {
                sub: userId,
                face_key: faceKey,
                new_name: newName
            }, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            // Update local state
            setFaceSuggestions(prevSuggestions =>
                prevSuggestions.map(suggestion =>
                    suggestion.face_key === faceKey
                        ? { ...suggestion, person_name: newName }
                        : suggestion
                )
            );
            handleFaceConfirmation(newName, faceKey, true);
            setEditingName("");
        } catch (error) {
            console.error('Error updating face name:', error);
            alert('An error occurred while updating the face name. Please try again.');
        }
    };


    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, faceKey: string) => {
        if (event.key === 'Enter') {
            handleNameEdit(faceKey, editingValue);
        }
    };

    const handleAutocompleteChange = (value: string) => {
        setEditingValue(value);

        // Convert known people to lowercase for case-insensitive matching
        const lowerCaseKnownPeople = knownPeople.map((person) => person.toLowerCase());

        // Filter known people that start with the input value, limited to 3 options
        const filteredKnownPeople = knownPeople.filter(person => person.toLowerCase().startsWith(value.toLowerCase())).slice(0, 3);

        // Check if the input value matches any known people
        const matchesStart = lowerCaseKnownPeople.some((person) => person.startsWith(value.toLowerCase()));

        // If there are no matches, add the "+ Add {value}" option
        if (!matchesStart) {
            // Create the "+ Add {value}" option
            const addOption = `+ ${value}`;

            // Set the tags with filtered known people and the add option
            setTags([...filteredKnownPeople, addOption]);
        } else {
            // Set the tags with the filtered known people only
            setTags(filteredKnownPeople);
        }
    };

    const handleAutocompleteSelect = (value: string, faceKey: string) => {
        if (value.startsWith('Add')) {
            const newName = value.match(/"(.*)"/)?.[1];
            if (newName) {
                // setKnownPeople([...knownPeople, newName]);
                handleNameEdit(faceKey, newName);
            }
        } else {
            handleNameEdit(faceKey, value);
        }
    };


    // todo: add person_key to params
    const handleFaceConfirmation = async (personName: string, faceKey: string, isConfirmed: boolean) => {
        try {
            // API call to confirm or unconfirm face
            const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/faces";
            const id_token = auth.IdToken;

            // sub = "sub_" + event['uid'].replace('-', '_')
            // person_key = event['key']
            // faces_neo4j_keys = [face['key'] for face in event['faces']]
            // faces_rekognition_keys = [face['rekognition_id'] for face in event['faces']]
            // logger.debug("naming faces based on the following data from event:\n" +
            //     f"sub: {sub}\n" +
            // f"person_key: {person_key}\n" +
            // f"faces_to_confirm: {faces_neo4j_keys}\n" +
            // f"faces_rekognition_keys: {faces_rekognition_keys}")

            await axios.patch(apiUrl, {
                uid: userId,
                key: personMap[personName], // todo: this should be replaced with the person key
                faces: [{'key': faceKey, 'rekognition_id': ""}],
            }, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            // Update local state
            setConfirmedFaces(prev => ({
                ...prev,
                [faceKey]: isConfirmed
            }));
        } catch (error) {
            console.error('Error confirming face:', error);
            alert('An error occurred while confirming the face. Please try again.');
        }
    };

    console.log(containerWidth)
    return (
        <div>
            <Flex direction="column" style={{ width: '100%', minHeight: '100vh', position: 'relative'}}>
                <Box style={{backgroundColor: 'rgba(255, 255, 255, 0.75)', // Solid white with 25% opacity
                    position: 'fixed', bottom: 0, zIndex: 2, width: containerWidth ? `${containerWidth}px` : '5px'}}>
                    <Flex justify="center">
                        <Button
                            mt="sm"
                            mb="sm"
                            size={"md"}
                            onClick={handleCreateEvent}
                            disabled={selectedImages.length === 0}
                            style={{width: '50%'}}
                        >
                            {selectedImages.length !== 0 ? (
                                `Create Event with ${selectedImages.length} Selected Image${selectedImages.length !== 1 ? 's' : ''}`
                            ) : (
                                'Select Images to Create an Event'
                            )}
                        </Button>
                    </Flex>
                </Box>
                <Title order={1}>Manually Group Images</Title>
                <Divider mt='xs' />
                <Blockquote color="blue" icon={<IconInfoCircle />} mt='lg' mb='lg'>
                    Select images that belong to the same event and click "Create Event" to group them together. This manual method gives you full control over how your images are organized.
                </Blockquote>

                <Flex
                    direction="row"
                    wrap="wrap"
                    gap={{ base: '3px', sm: '3px' }}
                    justify={{ sm: '3px' }}
                    // style={{ paddingBottom: '80px' }} // Add padding to account for the fixed button
                >
                    {images.map((image) => (
                        <Paper
                            key={image.key}
                            shadow="sm"
                            p="xs"
                            style={{
                                position: 'relative',
                                width: 'calc((100% - 15px) / 5)',
                                aspectRatio: 1 / 1,
                            }}
                        >
                            <Checkbox
                                checked={selectedImages.includes(image.key)}
                                onChange={() => handleImageSelect(image.key)}
                                style={{ position: 'absolute', top: 5, left: 5, zIndex: 1 }}
                            />
                            <ImageWithSkeleton
                                src={image.thumbnail_url}
                                alt={`Photo ${image.datetime ? `taken on ${image.datetime}` : 'with unknown date'}`}
                                onClick={() => handleImageSelect(image.key)}
                            />
                        </Paper>
                    ))}
                </Flex>

                {loading && (
                    <Flex justify="center" mt="xl">
                        <Loader size="lg" />
                    </Flex>
                )}

                {!hasMore && !loading && (
                    <Text ta="center" mt="xl" mb="lg">No more images to load</Text>
                )}

                {hasMore && !loading && (
                    <Group justify="center" mt="xl">
                        <Button onClick={loadMoreImages}>Load More Images</Button>
                    </Group>
                )}

            </Flex>

            <Modal opened={modalOpen} size="xl" onClose={() => setModalOpen(false)} title="Create New Event">
                {modalPage === 1 && (
                    <>
                        <Text size="lg" mb="md">Confirm Faces</Text>
                        <Grid>
                            {faceSuggestions.map((suggestion) => (
                                <Grid.Col span={3} key={suggestion.face_key}>
                                    <Card
                                        p={0}
                                        w="100%"
                                        style={{ aspectRatio: '4 / 5' }}
                                        onMouseEnter={() => setHoveredCard(suggestion.face_key)}
                                        onMouseLeave={() => setHoveredCard("")}
                                    >
                                        <Flex direction="column" h="100%">
                                            <Box style={{ flexBasis: '80%', position: 'relative' }}>
                                                <Image
                                                    src={suggestion.face_url}
                                                    height="100%"
                                                    width="100%"
                                                    fit="cover"
                                                />
                                            </Box>
                                            <Paper
                                                style={{
                                                    flexBasis: '20%',
                                                    width: '100%',
                                                    borderTopLeftRadius: 0,
                                                    borderTopRightRadius: 0
                                                }}
                                                bg="lightgray"
                                            >
                                                {editingName === suggestion.face_key ? (
                                                    <Autocomplete
                                                        placeholder="Enter name"
                                                        value={editingValue}
                                                        onChange={handleAutocompleteChange}
                                                        data={tags.slice(0, 3)} // Limit the displayed options to the first 3
                                                        onKeyDown={(e) => handleInputKeyDown(e, suggestion.face_key)}
                                                        onFocus={(e) => {
                                                            e.target.select();
                                                            setEditingValue(suggestion.person_name || "");
                                                        }}
                                                        onBlur={() => handleNameEdit(suggestion.face_key, editingValue)}
                                                        styles={{
                                                            root: {
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                height: '100%',
                                                                paddingLeft: '3px',
                                                                paddingRight: '3px',
                                                                zIndex: 3
                                                            },
                                                            input: {
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                        autoFocus
                                                    />
                                                ) : (
                                                    hoveredCard === suggestion.face_key ? (
                                                        <Group grow preventGrowOverflow={false} wrap="nowrap" h="100%" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                                            <Flex justify="center" align="center" w="15%" h="100%">
                                                                <Tooltip
                                                                    label={suggestion.person_name ? `This is not ${suggestion.person_name}`: 'Untrack this face'}
                                                                    withArrow
                                                                    openDelay={500}
                                                                    position="bottom"
                                                                >
                                                                    <Button
                                                                        variant="transparent"
                                                                        color="dark"
                                                                        onClick={() => suggestion.person_name ? handleFaceConfirmation(suggestion.person_name, suggestion.face_key, false) : handleUntrackFace(suggestion.face_key)}
                                                                        p={0}
                                                                        h="100%"
                                                                        w="100%"
                                                                    >
                                                                        {suggestion.person_name ? <IconX size={16} /> : <IconTrash size={16} />}
                                                                    </Button>
                                                                </Tooltip>
                                                            </Flex>
                                                            <Flex justify="center" align="center" w="70%" h="100%">
                                                                <Text
                                                                    size="sm"
                                                                    ta="center"
                                                                    c="dark"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => setEditingName(suggestion.face_key)}
                                                                >
                                                                    {suggestion.person_name ? `${suggestion.person_name}?` : "?"}
                                                                </Text>
                                                            </Flex>
                                                            <Flex justify="center" align="center" w="15%" h="100%">
                                                                {suggestion.person_name ? (
                                                                    <Tooltip
                                                                        label={`Confirm face as ${suggestion.person_name}`}
                                                                        withArrow
                                                                        openDelay={500}
                                                                        position="bottom"
                                                                    >
                                                                        <Button
                                                                            variant="transparent"
                                                                            color="dark"
                                                                            onClick={() => handleFaceConfirmation(suggestion.person_name, suggestion.face_key, true)}
                                                                            p={0}
                                                                            h="100%"
                                                                            w="100%"
                                                                        >
                                                                            <IconCheck size={16} />
                                                                        </Button>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Button
                                                                        variant="transparent"
                                                                        color="lightgray"
                                                                        onClick={() => setEditingName(suggestion.face_key)}
                                                                        p={0}
                                                                        h="100%"
                                                                        w="100%"
                                                                    >
                                                                        <IconPencil size={16} />
                                                                    </Button>
                                                                )}
                                                            </Flex>
                                                        </Group>
                                                    ) : (
                                                        <Flex justify="center" align="center" h="100%">
                                                            <Text
                                                                size="sm"
                                                                ta="center"
                                                                c="dark"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => setEditingName(suggestion.face_key)}
                                                            >
                                                                {suggestion.person_name ? `${suggestion.person_name}?` : "?"}
                                                            </Text>
                                                        </Flex>
                                                    )
                                                )}
                                            </Paper>
                                        </Flex>
                                    </Card>
                                </Grid.Col>
                            ))}
                        </Grid>
                        <Button
                            mt="xl"
                            fullWidth
                            onClick={handleModalNext}
                            rightSection={<IconArrowRight size={14}/>}
                        >
                            Next
                        </Button>
                    </>
                )}
                {modalPage === 2 && (
                    <>
                        <Paper shadow="sm" radius="lg" withBorder p="md" style={{backgroundColor: '#f0f0f0'}}>
                            {/* ... (rest of the existing JSX for selected images remains the same) */}
                        </Paper>

                        <Fieldset mt="md" legend="Add Event Details">
                            <FocusTrap>
                                <>
                                    <Flex align="center" mt="sm">
                                        {isEventDescriptionAI && <IconSparkles size={16} style={{marginRight: '8px'}}/>}
                                        <TextInput
                                            label="Event Description:"
                                            value={eventDescription}
                                            placeholder="e.g. Joel and Shannon's Wedding, Grace's Birthday, etc."
                                            onChange={(event) => {
                                                setEventDescription(event.currentTarget.value);
                                                setIsEventDescriptionAI(false);
                                            }}
                                            style={{
                                                flex: 1,
                                                backgroundColor: isEventDescriptionAI ? '#f0f0f0' : 'transparent'
                                            }}
                                        />
                                    </Flex>
                                    <Flex align="center" mt="md">
                                        {isLocationAI && <IconSparkles size={16} style={{marginRight: '8px'}}/>}
                                        <TextInput
                                            label="Location:"
                                            value={location}
                                            onChange={(event) => {
                                                setLocation(event.currentTarget.value);
                                                setIsLocationAI(false);
                                            }}
                                            style={{
                                                flex: 1,
                                                backgroundColor: isLocationAI ? '#f0f0f0' : 'transparent'
                                            }}
                                        />
                                    </Flex>
                                    <MonthPickerInput
                                        clearable
                                        label="Event Date:"
                                        value={eventDate}
                                        onChange={(date) => setEventDate(date)}
                                        mt="md"
                                    />
                                    <TagsInput
                                        mt="md"
                                        label="Keyword Tags:"
                                        placeholder="Enter tag"
                                        value={tags}
                                        onChange={setTags}
                                        clearable
                                    />
                                </>
                            </FocusTrap>
                        </Fieldset>
                        <Flex justify="flex-end">
                            <Button
                                mt={"md"}
                                fullWidth
                                onClick={confirmCreateEvent}
                            >
                                Confirm
                            </Button>
                        </Flex>
                    </>
                )}
            </Modal>
        </div>
    );
}