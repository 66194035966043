import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Text, Select, TextInput, Group, Stack, ActionIcon} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconTrash, IconPlus } from '@tabler/icons-react';
import {Person, LifeEvent} from "../../types/Person";

interface LifeEventsPanelProps {
    member: Person;
    onUpdate: (updatedMember: Person) => void;
}

export const LifeEventsPanel: React.FC<LifeEventsPanelProps> = ({ member, onUpdate }) => {
    const [lifeEvents, setLifeEvents] = useState<LifeEvent[]>(() => {
        const birthEvent: LifeEvent = {
            type: 'Birth',
            date: member.dateOfBirth ? new Date(member.dateOfBirth) : null,
            description: '',
            isLocked: true
        };
        return [birthEvent, ...(member.lifeEvents || [])];
    });
    const prevValidLifeEventsRef = useRef<LifeEvent[]>([]);

    const eventTypes = [
        'Birth', 'Death', 'Marriage', 'Divorce', 'Graduation', 'Career Change',
        'Relocation', 'Birth of Child', 'Retirement', 'Major Illness/Recovery',
        'Military Service', 'Award/Recognition', 'Other'
    ];

    const handleAddEvent = () => {
        setLifeEvents([...lifeEvents, { type: '', date: null, description: '' }]);
    };

    const handleRemoveEvent = (index: number) => {
        if (index === 0) return; // Prevent removal of birth event
        const updatedEvents = lifeEvents.filter((_, i) => i !== index);
        setLifeEvents(updatedEvents);
    };

    const handleUpdateEvent = (index: number, field: keyof LifeEvent, value: string | Date | null) => {
        const updatedEvents = lifeEvents.map((event, i) =>
            i === index ? { ...event, [field]: value } : event
        );
        setLifeEvents(updatedEvents);
    };

    const areEventsEqual = (events1: LifeEvent[], events2: LifeEvent[]) => {
        if (events1.length !== events2.length) return false;
        return events1.every((event, index) =>
            event.type === events2[index].type &&
            event.date?.getTime() === events2[index].date?.getTime()
        );
    };

    useEffect(() => {
        const validEvents = lifeEvents.filter(event => event.type && event.date);

        if (!areEventsEqual(validEvents, prevValidLifeEventsRef.current)) {
            console.log('Valid life events changed:', validEvents);
            onUpdate({ ...member, lifeEvents: validEvents.slice(1) }); // Exclude birth event from update
            prevValidLifeEventsRef.current = validEvents;
        }
    }, [lifeEvents, member, onUpdate]);

    return (
        <Box mt={20} p={20}>
            <Stack gap="md">
                {lifeEvents.map((event, index) => (
                    <Group key={index} align="flex-start">
                        <Select
                            data={eventTypes}
                            value={event.type}
                            onChange={(value) => handleUpdateEvent(index, 'type', value)}
                            placeholder="Select event type"
                            style={{ flex: 1 }}
                            disabled={event.isLocked}
                        />
                        <DateInput
                            value={event.date}
                            onChange={(value) => handleUpdateEvent(index, 'date', value)}
                            placeholder="Event date"
                            style={{ flex: 1 }}
                            disabled={event.isLocked}
                        />
                        <TextInput
                            value={event.description}
                            onChange={(e) => handleUpdateEvent(index, 'description', e.currentTarget.value)}
                            onBlur={() => {
                                const validEvents = lifeEvents.filter(event => event.type && event.date);
                                onUpdate({ ...member, lifeEvents: validEvents.slice(1) });
                            }}
                            placeholder="Event description"
                            style={{ flex: 2 }}
                        />
                        {event.isLocked ? (
                            <Box style={{ width: 28, height: 28 }} /> // Placeholder for locked events
                        ) : (
                            <ActionIcon color="red" onClick={() => handleRemoveEvent(index)} variant="outline">
                                <IconTrash size={16} />
                            </ActionIcon>
                        )}
                    </Group>
                ))}

                <Button
                    onClick={handleAddEvent}
                    leftSection={<IconPlus size={16} />}
                    variant="outline"
                >
                    Add Life Event
                </Button>
            </Stack>
        </Box>
    );
};