import React, { useState, useEffect } from 'react';
import { Box, Tabs, Button, SimpleGrid, Image } from '@mantine/core';
import { Person } from "../../types/Person";
import { RelationshipsPanel } from "./RelationshipPanel";
import { LifeEventsPanel } from "./LifeEventPanel";
import { PersonalInfoPanel } from "./PersonalInfoPanel";
import { NotesPanel } from "./NotesPanel";

interface PersonDetailsProps {
    member: Person;
    onUpdate: (updatedMember: Person) => void;
    onClose: () => void;
}

export const PersonDetailsModal: React.FC<PersonDetailsProps> = ({ member, onUpdate, onClose }) => {
    const [editedMember, setEditedMember] = useState<Person>(member);
    const [linkedPhotos, setLinkedPhotos] = useState<string[]>([]);

    useEffect(() => {
        // fetchAdditionalData();
        // fetchLinkedPhotos();
        updateEditedMemberToHaveFakeRelations();
    }, []);

    const updateEditedMemberToHaveFakeRelations = async () => {
        let fakedMember = editedMember;
        fakedMember.relationships = [{type: "father", name: "Joel"}, {type: "sibling", name: "Ava Raper"}];
        fakedMember.notes = "hey!"
        setEditedMember(fakedMember);
    };

    const fetchAdditionalData = async () => {
        // TODO: Implement API call to fetch additional genealogical data
        // Update editedMember state with the fetched data
    };

    const fetchLinkedPhotos = async () => {
        // TODO: Implement API call to fetch linked photos
        // Update linkedPhotos state with the fetched data
    };

    const handleUpdate = (updatedMember: Person) => {
        setEditedMember(updatedMember);
        onUpdate(updatedMember);
    };

    return (
        <Tabs defaultValue="personal">
            <Tabs.List>
                <Tabs.Tab value="personal">Personal Information</Tabs.Tab>
                <Tabs.Tab value="relationships">Relationships</Tabs.Tab>
                <Tabs.Tab value="events">Life Events</Tabs.Tab>
                <Tabs.Tab value="notes">Notes</Tabs.Tab>
                <Tabs.Tab value="photos">Linked Photos</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="personal">
                <PersonalInfoPanel member={editedMember} onUpdate={handleUpdate} />
            </Tabs.Panel>

            <Tabs.Panel value="relationships">
                <RelationshipsPanel member={editedMember} onUpdate={handleUpdate} />
            </Tabs.Panel>

            <Tabs.Panel value="events">
                <LifeEventsPanel member={editedMember} onUpdate={handleUpdate} />
            </Tabs.Panel>

            <Tabs.Panel value="notes">
                <NotesPanel member={editedMember} onUpdate={handleUpdate} />
            </Tabs.Panel>

            <Tabs.Panel value="photos">
                {/*<SimpleGrid cols={3}>*/}
                {/*    {linkedPhotos.map((photo, index) => (*/}
                {/*        <Image key={index} src={photo} alt={`Linked photo ${index + 1}`} />*/}
                {/*    ))}*/}
                {/*</SimpleGrid>*/}
            </Tabs.Panel>
        </Tabs>
    );
};