import React, { useState, useEffect, useRef } from 'react';
import { Box, Textarea } from '@mantine/core';
import { Person } from "../../types/Person";

interface NotesPanelProps {
    member: Person;
    onUpdate: (updatedMember: Person) => void;
}

export const NotesPanel: React.FC<NotesPanelProps> = ({ member, onUpdate }) => {
    const [notes, setNotes] = useState(member.notes || '');
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const lastSavedRef = useRef(notes);

    const saveNotes = () => {
        if (notes !== lastSavedRef.current) {
            onUpdate({ ...member, notes });
            lastSavedRef.current = notes;
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            saveNotes();
        };
    }, []);

    const handleNotesChange = (value: string) => {
        setNotes(value);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            saveNotes();
        }, 2000); // Save after 2 seconds of inactivity
    };

    return (
        <Box mt={15} p={20}>
            <Textarea
                size="lg"
                placeholder={`This is a great place to jot down any stories/memories you have about/with ${member.name}`}
                autosize
                minRows={2}
                value={notes}
                onChange={(event) => handleNotesChange(event.currentTarget.value)}
                onBlur={saveNotes}
            />
        </Box>
    );
};