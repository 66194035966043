// FamilyTree.tsx
import React, { useState, useEffect } from 'react';
import { Flex, Card, Avatar, Text, Blockquote, Title, Divider, Skeleton } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import axios from 'axios';
import { PaperProps } from '@mantine/core/lib';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { PersonCard } from './PersonCard';
import { PersonSkeleton} from './PersonSkeleton';

// Import types
import { Person } from '../../types/Person';

interface FamilyProps extends PaperProps {
    userId: string;
    auth: AuthenticationResultType;
}

export function FamilyTree({ userId, auth }: FamilyProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [familyMembers, setFamilyMembers] = useState<Person[]>([]);

    useEffect(() => {
        loadFamilyMembers();
    }, []);

    const loadFamilyMembers = async () => {
        try {
            let apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person";
            let id_token = auth.IdToken;

            const response = await axios.get(apiUrl, {
                params: {
                    sub: userId
                },
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            const familyMembers: Person[] = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            console.log(familyMembers);
            setFamilyMembers(familyMembers);
        } catch (error) {
            console.error('Error fetching family members:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdate = async (updatedMember: Person) => {
        // try {
        //     let apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person";
        //     let id_token = auth.IdToken;
        //
        //     const response = await axios.put(apiUrl,
        //         { ...updatedMember, sub: userId },
        //         {
        //             headers: {
        //                 'Authorization': `Bearer ${id_token}`,
        //                 'Content-Type': 'application/json'
        //             }
        //         }
        //     );
        //
        //     if (response.status === 200) {
        //         // Update the local state
        //         setFamilyMembers(prevMembers =>
        //             prevMembers.map(member =>
        //                 member.id === updatedMember.id ? updatedMember : member
        //             )
        //         );
        //     } else {
        //         console.error('Failed to update family member');
        //     }
        // } catch (error) {
        //     console.error('Error updating family member:', error);
        // }
    };

    return (
        <Flex direction="column">
            <Title order={1}>Identify Faces</Title>
            <Divider mt="xs" />
            <Blockquote color="blue" icon={<IconInfoCircle />} mt="lg" mb="lg">
                <p>
                    Your family tree isn't just a record of the past—it's the key to organizing and preserving your memories. By adding family members and their birthdays, you enable Sort My Shoebox's AI to more accurately predict when your photos were taken, helping you better organize your photo collection.
                </p>
                <p>
                    In the future, this space will also serve as a hub for tracking and storing genealogical information, such as marriages, children, occupations, and significant life events. With AI-powered suggestions, we'll help uncover the hidden clues in each photograph as you piece together your family history.
                </p>
            </Blockquote>
            <Flex
                wrap="wrap"
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: '10px', sm: '10px' }}
                justify="flex-start"
            >
                {isLoading
                    ? Array(4).fill(null).map((_, index) => <PersonSkeleton key={`skeleton-${index}`} />)
                    : familyMembers.map((member) => (
                        <PersonCard key={member.id} member={member} onUpdate={onUpdate} />
                    ))}
            </Flex>
        </Flex>
    );
}