import React, { useState } from 'react';
import { Box, Center, Group, Avatar, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { Person } from "../../types/Person";

interface PersonalInfoPanelProps {
    member: Person;
    onUpdate: (updatedMember: Person) => void;
}

export const PersonalInfoPanel: React.FC<PersonalInfoPanelProps> = ({ member, onUpdate }) => {
    const [editedMember, setEditedMember] = useState<Person>(member);

    const handleInputChange = (field: keyof Person, value: any) => {
        const updatedMember = { ...editedMember, [field]: value };
        setEditedMember(updatedMember);
        onUpdate(updatedMember);
    };

    return (
        <Box mt={20} p={20}>
            <Center mb={30}>
                <Avatar
                    src={member.profilePictureUrl}
                    radius="xl"
                    alt={`${member.name}`}
                    size={180}
                />
            </Center>
            <Group grow align="flex-start">
                <TextInput
                    size="md"
                    label="First Name"
                    placeholder="Enter first name"
                    value={editedMember.firstName || ''}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                    required
                />
                <TextInput
                    size="md"
                    label="Last Name"
                    placeholder="Enter last name"
                    value={editedMember.lastName || ''}
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                    required
                />
            </Group>
            <DateInput
                size="md"
                label="Date of Birth"
                placeholder="MM/DD/YYYY"
                value={editedMember.dateOfBirth ? new Date(editedMember.dateOfBirth) : null}
                onChange={(date) => handleInputChange('dateOfBirth', date?.toISOString())}
                valueFormat="MM/DD/YYYY"
                required
                mt="md"
            />
        </Box>
    );
};